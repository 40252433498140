import * as Bowser from 'bowser'
import { AxiosResponse } from 'axios'
import { Route } from 'vue-router/types'
import microApi from '../lib/micro-client'
import { ActionTree, ActionContext } from 'vuex/types'
import { questions, solutions } from '../lib/contentful-clients'
import { Asset, AssetCollection, Entry, EntryCollection, Sys } from 'contentful'
import { CtaBanner, FlagInfo, QuestionContent, SolutionStep, UiText } from '@typesCustom/contentful'
import {
  Address,
  AddressLookup,
  Answer,
  AnswerCollection,
  HouseScanFeedbackRequestBody,
  HouseScanResult,
  HttpResponse,
  Question,
  QuestionObject,
  SolutionObject,
  RootState,
  Theme,
} from '@typesCustom/index'

const apiVersion: string = '1.2'
const addressApi: string = process.env.VUE_APP_ADDRESS_API || ''
const production: boolean = process.env.NODE_ENV === 'production'
const appName: string = process.env.VUE_APP_NAME || 'GRH'

const actions: ActionTree<RootState, RootState> = {
  /**
   * Fetch all the UI texts from Contentful
   */
  async loadTexts({ state, commit, dispatch, getters }: ActionContext<RootState, RootState>) {
    try {
      const [questionTexts, ctaBanners, flagInfo, uiTexts, solutionTexts, assets, uiAssets]: [
        EntryCollection<QuestionContent>,
        EntryCollection<CtaBanner>,
        EntryCollection<FlagInfo>,
        EntryCollection<UiText>,
        EntryCollection<SolutionStep>,
        AssetCollection,
        AssetCollection,
      ] = await Promise.all([
        questions.getEntries<QuestionContent>({ content_type: 'questionContent' }),
        questions.getEntries<CtaBanner>({ content_type: 'ctaBanner', order: 'sys.createdAt' }),
        questions.getEntries<FlagInfo>({ content_type: 'flagInfo' }),
        questions.getEntries<UiText>({ content_type: 'uiText' }),
        solutions.getEntries<SolutionStep>({ content_type: 'energySavingRoadmap', include: 4, order: 'fields.order' }),
        solutions.getAssets(),
        questions.getAssets({ 'fields.title[match]': 'logo' }),
      ])

      const texts = {
        questions: questionTexts.items.reduce((acc: QuestionObject, { fields }: Entry<QuestionContent>) => {
          acc[fields.uid] = fields
          return acc
        }, {}),
        banners: ctaBanners.items.map(({ fields }: Entry<CtaBanner>) => fields),
        solutions: solutionTexts.items.map(({ fields }: Entry<SolutionStep>) => fields),
        media: { ...getAssets(assets), ...getAssets(uiAssets) },
        ui: uiTexts.items.reduce((acc: { [key: string]: string }, { fields }: Entry<UiText>) => {
          acc[fields.slug] = fields.content
          return acc
        }, {}),
        flagInfo: flagInfo.items.reduce((acc: { [key: string]: FlagInfo }, { fields }: Entry<FlagInfo>) => {
          acc[fields.slug] = fields
          return acc
        }, {}),
      }
      commit('LOAD_TEXTS', texts)

      dispatch('loopLogos', getters.logos)
    } catch (error) {
      dispatch('handleError', error)
    }
  },

  /**
   * Fetch 'themes' (pages) from API
   */
  async loadThemes({ state, commit, dispatch }: ActionContext<RootState, RootState>) {
    if (state.themes.length) {
      return
    }
    try {
      const { data }: { data: Theme[] } = await microApi.get(`/${apiVersion}/themes`)
      commit('LOAD_THEMES', data)
    } catch (error) {
      dispatch('handleError', error)
    }
  },

  /**
   * Post answers to API and fetch the questions for the next 'theme'
   */
  async postAnswers({ state, commit, getters, dispatch }: ActionContext<RootState, RootState>, answers: Answer[]) {
    const postData: AnswerCollection = {
      address: String(state.address.id),
      answers,
      type: appName,
    }
    // Delay for 2,5 seconds on address lookup
    const timer = state.active ? 100 : 2500

    commit('UI_STATE', 'loading')
    try {
      commit('CLEAR_CACHE')
      const [{ data }]: Array<{ data: Question[] }> = await Promise.all([
        microApi.post(`/${apiVersion}/answers/${getters.next}`, postData),
        new Promise(resolve => setTimeout(resolve, timer)),
      ])
      commit('SET_ACTIVE', getters.next)
      commit('SET_QUESTIONS', data)
      commit('UI_STATE', 'resting')
    } catch (error) {
      dispatch('handleError', error)
    }
  },

  /**
   * Post complete set of answers and get HouseScan result from API
   */
  async getResults({ state, commit, getters, dispatch }: ActionContext<RootState, RootState>, answers: Answer[]) {
    const postData: AnswerCollection = {
      address: String(state.address.id),
      answers,
      type: appName,
    }
    commit('UI_STATE', 'fetching')
    try {
      commit('CLEAR_CACHE')
      const [response]: Array<AxiosResponse<HouseScanResult[]>> = await Promise.all([
        microApi.post(`/${apiVersion}/housescan`, postData),
        new Promise(resolve => setTimeout(resolve, 2500)),
      ])
      const results: HouseScanResult[] = response.data
      commit('SET_RESULTS', { results })
      commit('SET_ACTIVE', 'results')
      commit('UI_STATE', 'resting')
      window.dataLayer.push({
        event: 'houseScanDone',
        uuid: state.uuid,
        answers: getters.gtmAnswers,
        address: state.address,
      })
    } catch (error) {
      dispatch('handleError', error)
    }
  },

  /**
   * Get address from API by id
   */
  async getAddress({ state, commit, dispatch }: ActionContext<RootState, RootState>, id: string | number) {
    try {
      const { data }: { data: Address } = await microApi.get(`/${apiVersion}/address/${id}`)
      await dispatch('setAddress', data)
    } catch (error) {
      dispatch('handleError', error)
    }
  },

  /**
   * Lookup address from adress API (postcode.nl proxy)
   */
  async setAddress({ state, commit, dispatch }: ActionContext<RootState, RootState>, address: Address) {
    commit('SET_ACTIVE', '') // Ensure 'home' is loaded first
    commit('SET_ADDRESS', address)
    window.dataLayer.push({ event: 'houseScanStarted', address, uuid: state.uuid })
    await dispatch('postAnswers', [])
  },

  /**
   * Send energy answers to backend to compare consumption with averages
   */
  async getEnergyComparison({ state, commit, getters, dispatch }: ActionContext<RootState, RootState>) {
    const postData: AnswerCollection = {
      address: String(state.address.id),
      answers: getters.answers,
      type: appName,
    }
    try {
      const { data }: { data: Question[] } = await microApi.post(`/${apiVersion}/energy-comparison`, postData)
      commit('ENERGY_COMPARISON', data)
    } catch (error) {
      // dispatch('handleError', error);
    }
  },

  /**
   * Clear user address from store
   */
  clearAddress({ commit }: ActionContext<RootState, RootState>, resetUi: boolean = false) {
    if (resetUi) {
      commit('UI_STATE', 'loading')
      commit('SET_ACTIVE', '')
    }
    commit('CLEAR_ADDRESS')
    commit('CLEAR_ANSWERS')
  },

  /**
   * Start over: clear all answers from store and go back to home
   */
  startAgain({ commit }: ActionContext<RootState, RootState>) {
    commit('CLEAR_ADDRESS')
    commit('CLEAR_ANSWERS')
    commit('SET_ACTIVE', '')
  },

  /**
   * Generic error handler stores error for display
   */
  // tslint:disable-next-line
  handleError({ state, commit, getters }: ActionContext<RootState, RootState>, error: any) {
    commit('UI_STATE', 'error')

    const body: AnswerCollection = {
      address: String(state.address.id),
      answers: getters.answers,
      type: appName,
    }

    const { width, height, availWidth, availHeight, colorDepth, pixelDepth } = window.screen

    const plugins = Array.from(window.navigator.plugins).map((plugin: Plugin) => {
      const { name, description, filename } = plugin
      return { name, description, filename }
    })

    const errorRequest: HouseScanFeedbackRequestBody = {
      type: 'bug',
      body,
      technicalInfo: {
        ...Bowser.parse(window.navigator.userAgent),
        step: state.active,
        screen: { width, height, availWidth, availHeight, colorDepth, pixelDepth },
        error,
        plugins,
      },
    }

    // tslint:disable-next-line
    console.log({ ...errorRequest })
    microApi.post(`/${apiVersion}/feedback/housescan`, errorRequest)
    const status = error.response ? error.response.status : 400
    commit('HTTP_ERROR', { status, statusText: getters.uiText('error-message') })
    commit('UI_STATE', 'resting')
  },

  /**
   * Store answer
   */
  saveAnswer({ commit, getters, state }: ActionContext<RootState, RootState>, answer: Answer) {
    window.dataLayer.push({ event: 'housescanQuestionAnswered', uuid: state.uuid, answer: getters.gtmAnswer(answer) })
    commit('SAVE_ANSWER', answer)
  },

  /**
   * Remove answer
   */
  deleteAnswer({ commit, getters, state }: ActionContext<RootState, RootState>, answer: Answer) {
    commit('DELETE_ANSWER', answer)
  },

  /**
   * Register a required question on component mount
   */
  require({ commit }: ActionContext<RootState, RootState>, questionId: string) {
    commit('REQUIRE', questionId)
  },

  /**
   * Un-register a required question on component destroy
   */
  unRequire({ commit }: ActionContext<RootState, RootState>, questionId: string) {
    commit('UN_REQUIRE', questionId)
  },

  /**
   * Loop continuously through logos for loading screen
   */
  loopLogos({ commit, state }: ActionContext<RootState, RootState>, logos: string[]) {
    const len = logos.length
    let index = 0

    commit('SET_LOGO', logos[0])
    index++
    setInterval(() => {
      if (!state.active && !state.httpResponse) {
        commit('SET_LOGO', logos[index])
      }
      index = (index + 1) % len
    }, 2000)
  },

  /**
   * Send customer email to PDF generator API
   */
  async sendEmail(
    { commit, getters, state }: ActionContext<RootState, RootState>,
    { email, optin }: { email: string; optin: boolean },
  ): Promise<boolean> {
    const postData: AnswerCollection = {
      address: String(state.address.id),
      answers: getters.answers,
      type: appName,
    }
    if (optin) {
      window.dataLayer.push({ event: 'emailOptin', uuid: state.uuid, email, type: appName })
    }
    try {
      const { status } = await microApi.post(`/${apiVersion}/download/housescan`, { email, optin, ...postData })
      return status < 300
    } catch (error) {
      // tslint:disable-next-line
      console.error({ ...error })
    }
    return false
  },
}

/**
 * Helper for mapping asset collections
 */
const getAssets = (assets: AssetCollection): { [key: string]: string } =>
  assets.items.reduce((acc: { [key: string]: string }, { fields }: Asset) => {
    acc[fields.title] = fields.file.url
    return acc
  }, {})

export default actions
