









































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import microApi from '../lib/micro-client'
import { validate } from '../lib/validation'
import { Address } from '@typesCustom/index'
import PrivacyLink from './PrivacyLink.vue'

const apiVersion: string = '1.2'
const askConsent: boolean = !!process.env.VUE_APP_ASK_CONSENT

export default Vue.extend({
  components: {
    PrivacyLink,
  },
  computed: {
    ...mapGetters(['uiText']),
    address(): Address {
      return this.$store.state.address
    },
    found(): boolean {
      return this.zipcode !== '' && this.housenumber !== ''
    },
    formatted(): string {
      return this.zipcode.replace(/[^a-z0-9+]+/gi, '').toUpperCase()
    },
    queryString(): string {
      return `zipcode=${this.formatted}&housenumber=${this.housenumber}`
    },
    disabled(): boolean {
      return !(this.consent && this.zipcode && this.housenumber)
    },
  },
  data() {
    return {
      askConsent,
      consent: !askConsent,
      zipcode: '',
      housenumber: '',
      addition: '',
      additions: [] as string[],
      loading: false,
      error: false,
      message: '',
    }
  },
  methods: {
    /**
     * Lookup address when user has entered zipcode and number (plus addition)
     */
    async lookup() {
      if (validate(this.formatted, 'isPostalCode') && this.housenumber.length) {
        this.error = false
        this.loading = true
        try {
          this.$store.dispatch('clearAddress')
          const response = await microApi.get(`/${apiVersion}/verifyaddress?${this.queryString}`)
          const address: Address | undefined = this.findAddress(response.data)
          if (address) {
            await this.$store.dispatch('setAddress', address)
          }
        } catch (e) {
          this.error = true
        }
        this.loading = false
        return
      }
      this.error = true
    },

    /**
     * Find address in collection returned from API
     */
    findAddress(addresses: Address[]): Address | undefined {
      const additions = addresses.map((x: Address) => x.letter + x.addition)

      // Show select menu if multiple addresses
      if (!this.addition && !additions.includes('')) {
        this.additions = additions.sort()
        return undefined
      }

      // Try 'addition' as well as 'letter'
      const address = addresses.find((x: Address) => {
        const addition = this.addition.replace(/[^0-9a-z]/gi, '').toUpperCase()
        return (
          (!addition && !x.addition && !x.letter) ||
          (addition && x.addition.toUpperCase() === addition) ||
          (addition && x.letter.toUpperCase() === addition) ||
          // (addition && x.addition.toUpperCase() + x.letter.toUpperCase() === addition) ||
          (addition && x.letter.toUpperCase() + x.addition.toUpperCase() === addition)
        )
      })
      if (address) {
        return address
      }
      this.error = true
      return undefined
    },
    /**
     * Reset search input fields
     */
    reset() {
      this.$store.dispatch('clearAddress')
      this.zipcode = this.formatted
      this.addition = ''
      this.additions = []
    },
  },
  created() {
    this.message = this.$store.getters.uiText('geen-adres')
  },
  watch: {
    zipcode(newVal: string, oldVal: string) {
      if (newVal !== oldVal && this.error) {
        this.error = false
      }
    },
    housenumber(newVal: string, oldVal: string) {
      if (newVal !== oldVal && this.error) {
        this.error = false
      }
    },
  },
})
