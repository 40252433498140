




























import Vue from 'vue'
import { HttpResponse, Question } from '@typesCustom/index'

import OpenData from '../components/OpenData.vue'
import ButtonBar from '../components/ButtonBar.vue'
import ProgressBar from '../components/ProgressBar.vue'
import AddressLookup from '../components/AddressLookup.vue'

const TextInput = () => import('../components/TextInput.vue')
const EnergyData = () => import('../components/EnergyData.vue')
const NumberInput = () => import('../components/NumberInput.vue')
const SelectInput = () => import('../components/SelectInput.vue')
const BooleanInput = () => import('../components/BooleanInput.vue')
const QuestionSingle = () => import('../components/QuestionSingle.vue')
const QuestionNumeric = () => import('../components/QuestionNumeric.vue')
const QuestionMultiple = () => import('../components/QuestionMultiple.vue')

export default Vue.extend({
  components: {
    AddressLookup,
    ButtonBar,
    ProgressBar,
  },
  computed: {
    questions(): Question[] {
      return this.$store.getters.questions.filter((q: Question) => {
        return q.format === 'openData' || this.$store.state.verified
      })
    },
    logo(): string {
      return this.$store.state.texts.media['company-logo'] || ''
    },
    error(): HttpResponse {
      return this.$store.state.httpResponse
    },
    alt(): string {
      return `Logo ${process.env.VUE_APP_FULL_NAME}`
    },
  },
  data() {
    return {
      inputTypes: {
        boolean: BooleanInput,
        energy: EnergyData,
        multipleChoice: QuestionMultiple,
        number: NumberInput,
        openData: OpenData,
        option: SelectInput,
        singleChoice: QuestionSingle,
        slider: QuestionNumeric,
        text: TextInput,
      },
    }
  },
})
