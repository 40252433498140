












































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { validate } from '../lib/validation'

const messages = {
  invalid: '',
  responseError: '',
}

let timeout: number = 0

export default Vue.extend({
  computed: {
    ...mapGetters(['uiText']),
    disabled(): boolean {
      const failCheck = this.submitting || this.success || this.hasError
      return this.appName === 'VEH' ? !this.optin || failCheck : failCheck
    },
  },
  data() {
    return {
      email: '' as string,
      error: '' as string,
      success: false as boolean,
      submitting: false as boolean,
      hasError: true as boolean,
      optin: false as boolean,
      appName: process.env.VUE_APP_NAME || ('GRH' as string),
    }
  },
  methods: {
    async submit() {
      this.submitting = true
      const optin = this.appName === 'VEH' ? false : this.optin

      if (await this.$store.dispatch('sendEmail', { email: this.email, optin })) {
        this.error = ''
        this.success = true
      } else {
        this.error = messages.responseError
        this.hasError = true
      }
      this.submitting = false
    },
    validateInput() {
      clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        this.error = validate(this.email, 'isEmail') ? '' : messages.invalid
        this.hasError = Boolean(this.error)
      }, 750)
    },
  },

  created() {
    messages.invalid = this.$store.getters.uiText('email-ongeldig')
    messages.responseError = this.$store.getters.uiText('email-response-ongeldig')
  },
})
